@use './mixins' as *;

@include keyframes(spin) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@include keyframes(pop-in) {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(pop-out) {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@include keyframes(fadein) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(fadeout) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include keyframes(flyin) {
  0% {
    opacity: 0;
    transform: translateY(-2.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(flyout) {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2.5rem);
  }
}

@include keyframes(preloader) {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}

@include keyframes(shift-margin) {
  0% {
    margin-left: 0;
  }
  15% {
    margin-left: 1.25rem;
  }
  90% {
    margin-left: 1.25rem;
  }
  100% {
    margin-left: 0;
  }
}

@include keyframes(fade-in-out) {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    transform: translateY(-0.25rem);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 0;
    transform: translateY(-0.25rem);
  }
  100% {
    opacity: 0;
  }
}
