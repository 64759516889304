@use 'breakpoints' as *;

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin image-circled($width) {
  @include square($width);

  border-radius: $width;
  overflow: hidden;
  img {
    width: 100%;
  }
}

@mixin clearfix() {
  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

@mixin spacer($property: null, $position: null, $space: null) {
  #{$property}-#{$position}: unquote($space) !important;
}

@mixin break-word() {
  word-wrap: break-word;
  word-break: keep-all;
}

@mixin ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin uppercase() {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

@mixin uppercase-bold() {
  text-transform: uppercase;
  letter-spacing: 0.015625rem;
}

@mixin heading-1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 2.75rem;
}

@mixin heading-2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
}

@mixin heading-3 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
}

@mixin heading-4 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.75rem;
}

@mixin heading-5 {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.5rem;
}

@mixin heading-6 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
}

@mixin paragraph {
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin caption-1 {
  font-size: 0.875rem;
  line-height: 1rem;
}

@mixin caption-2 {
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin mq($key, $scale: up) {
  $size: map-get($breakpoints, $key);

  @if $scale == up {
    @media only screen and (min-width: $size) {
      @content;
    }
  }

  @if $scale == down {
    @media only screen and (max-width: $size) {
      @content;
    }
  }
}

@mixin pseudo($loc: before, $content: '', $pos: absolute) {
  position: relative;

  &::#{$loc} {
    content: $content;
    position: $pos;
    @content;
  }
}

@mixin button-reset {
  padding: 0;
  border: none;
  background-color: transparent;
  text-align: left;
}

@mixin form-field {
  color: var(--blue-900);
  background-color: var(--white);
  width: 100%;
  border-radius: 0.125rem;
  padding: 0.5rem;
  border: 0.0625rem solid var(--blue-400);
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

  &[disabled] {
    &:disabled {
      background-color: var(--grey-200);
      -webkit-text-fill-color: var(--body-color);
      opacity: 1;
    }
  }
}

@mixin form-field-focus {
  background-color: var(--white);
  border-bottom: 0.0625rem solid var(--info-400);
  box-shadow: 0 0.0625rem 0 0 var(--info-400), 0 0 0.5rem var(--grey-400);
  outline: none;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@mixin form-field-bao {
  color: var(--blue-500);
  border: 0.0625rem solid var(--blue-200);
  border-radius: 0.3125rem;
}

@mixin form-field-bao-focus {
  border-bottom: 0.0625rem solid var(--blue-400);
  box-shadow: 0 0.0625rem 0 0 var(--blue-400), 0 0 0.5rem var(--blue-200);
}

@mixin no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
