@use '../abstracts' as *;

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

body {
  font-family: var(--font-family), sans-serif;
  color: var(--body-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

b,
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

p {
  margin: 0 0 1rem;
}

small {
  @include caption-2;
}

.heading-1 {
  @include heading-1();
  margin: 0 0 1.5rem;
}

.heading-2 {
  @include heading-2();
  margin: 0 0 1rem;
}

.heading-3 {
  @include heading-3();
  margin: 0 0 1rem;
}

.heading-4 {
  @include heading-4();
  margin: 0 0 1.5rem;
}

.heading-5 {
  @include heading-5();
  margin: 0 0 1.5rem;
}

.heading-6 {
  @include heading-6();
  margin: 0 0 1.5rem;
}

.caption-1 {
  @include caption-1();
}

.caption-2 {
  @include caption-2();
}

@media print {
  // comply for now
  $p-ui-sb: 4.2mm;
  $p-ui-sr: 1.25;
  $p-ui-base-line-height: 5.56mm;
  $p-ui-base-font-size: $p-ui-sb;

  h1,
  .p-header--level-1 {
    font-size: $p-ui-base-font-size * $p-ui-sr * $p-ui-sr * $p-ui-sr * $p-ui-sr * $p-ui-sr;
    line-height: $p-ui-base-line-height * 3;
    margin-bottom: $p-ui-base-line-height * 2;
  }
  h2,
  .p-header--level-2 {
    font-size: $p-ui-base-font-size * $p-ui-sr * $p-ui-sr * $p-ui-sr * $p-ui-sr;
    margin-bottom: $p-ui-base-line-height * 2;
  }
  h3,
  .p-header--level-3 {
    font-size: $p-ui-base-font-size * $p-ui-sr * $p-ui-sr * $p-ui-sr;
    line-height: $p-ui-base-line-height * 2;
    margin-bottom: $p-ui-base-line-height;
  }
  h4,
  .p-header--level-4 {
    font-size: $p-ui-base-font-size * $p-ui-sr * $p-ui-sr;
    line-height: $p-ui-base-line-height * 2;
    margin-bottom: $p-ui-base-line-height;
  }
  h5,
  .p-header--level-5 {
    font-size: $p-ui-base-font-size * $p-ui-sr;
    line-height: $p-ui-base-line-height;
    margin-bottom: $p-ui-base-line-height;
  }
  h6,
  .p-header--level-6 {
    font-size: $p-ui-base-font-size;
    line-height: $p-ui-base-line-height;
    margin-bottom: 2mm;
  }
  p,
  .p-paragraph {
    font-size: $p-ui-base-font-size;
    line-height: 6.5mm;
    margin-bottom: $p-ui-base-line-height;
  }
  .p-paragraph {
    p {
      margin: 0;
    }
  }
  .p-small {
    font-size: 3mm;
  }
  ul,
  ol {
    margin: 4.25mm 0;
    padding-left: 10.45mm;
  }
}
