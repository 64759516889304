.error-screen {
  background-color: var(--white);
  width: 100vi;
  height: 100vb;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-block-start: 4rem;

  .pelckmans-logo {
    width: 16rem;
  }

  .back-to-home {
    margin-block-start: 1rem;
  }
}
