@use '../abstracts' as *;

:root {
  @each $color, $shade in $colors {
    @each $prop, $value in $shade {
      --#{$color}-#{$prop}: #{$value};
    }
  }

  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);

  --assignments-color: hsl(36, 100%, 50%);

  --font-family: 'Open Sans';
  --body-color: var(--secondary-400);
  --primary-text-color: var(--blue-900);
  --heading-color: var(--blue-900);
  --link-color: var(--blue-900);
  --sidebar-bg-color: var(--blue-900);
  --header-bg-color: var(--blue-900);
  --module-color: var(--blue-900);
  --material-sidebar-bg-color: var(--grey-200);

  .body--bao {
    @each $color, $shade in $bao-colors {
      @each $prop, $value in $shade {
        --#{$color}-#{$prop}: #{$value};
      }
    }

    --font-family: 'Mikado';
    --body-color: var(--blue-500);
    --heading-color: var(--blue-600);
    --link-color: var(--blue-600);
    --sidebar-bg-color: var(--blue-400);
    --header-bg-color: var(--blue-400);
    --module-color: var(--blue-400);
    --material-sidebar-bg-color: var(--blue-100);
    --primary-text-color: var(--blue-600);
  }
}
